import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@chakra-ui/react';

import { Croatia } from '@/components/features/Croatia';
import { Layout } from '@/components/shared/Layout/Layout';
import { Macedonia } from '@/components/features/Macedonia';
import { Montenegro } from '@/components/features/Montenegro';
import { getOfferCountry } from '@/utils/serializer';
import { ContentLayout } from '@/components/shared/Layout/ContentLayout';
import { Slovenia } from '@/components/features/Slovenia';
import { ErrorBoundary } from '@/components/shared/ErrorBoundary';

const CroatiaPage = () => {
  return (
    <Layout country="hr" limitLanguages={['en', 'hr']} details={[0, 1, 2]}>
      <Croatia isOffer />
    </Layout>
  );
};

const MacedoniaPage = () => {
  return (
    <Layout country="mk" limitLanguages={['en', 'mk']}>
      <Macedonia isOffer />
    </Layout>
  );
};

const MontenegroPage = () => {
  return (
    <Layout country="me" limitLanguages={['en', 'hr', 'sr', 'cnr']} details={[0, 2]}>
      <Montenegro isOffer />
    </Layout>
  );
};

const SloveniaPage = () => {
  return (
    <Layout country="si" limitLanguages={['en', 'sl']}>
      <Slovenia isOffer />
    </Layout>
  );
};

export const Offer = () => {
  const { t } = useTranslation();
  const [country] = useState(() => getOfferCountry());
  let content = (
    <Layout country="hr" limitLanguages={['hr', 'en']}>
      <ContentLayout>
        <Heading>{t('common.wrongLink')}</Heading>
        <Text>{t('common.checkLink')}</Text>
      </ContentLayout>
    </Layout>
  );

  if (country?.key === 'hr') {
    content = <CroatiaPage />;
  } else if (country?.key === 'mk') {
    content = <MacedoniaPage />;
  } else if (country?.key === 'me') {
    content = <MontenegroPage />;
  } else if (country?.key === 'si') {
    content = <SloveniaPage />;
  }

  return <ErrorBoundary>{content}</ErrorBoundary>;
};
